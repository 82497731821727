<template>
  <div>
    <div class="header d-flex align-items-center">
      <a-menu
        class="w-75 mr-3"
        v-model="current"
        mode="horizontal"
        @click="handleClickTab"
      >
        <a-menu-item key="personal">Umum</a-menu-item>
        <a-menu-item key="finance">Kondisi Ekonomi</a-menu-item>
        <a-menu-item key="location">Demografi</a-menu-item>
        <a-menu-item key="achievement">Prestasi & Beasiswa</a-menu-item>
      </a-menu>
      <a-dropdown class="w-25 mt-5">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item v-for="year in years" :key="year.tahun">{{
            year.tahun
          }}</a-menu-item>
        </a-menu>
        <a-button type="primary" shape="round">
          {{ schoolYearActive }} <a-icon type="down" />
        </a-button>
      </a-dropdown>
    </div>
    <LoadingState v-if="isLoading" />
    <template v-else>
      <div v-if="current[0] === 'personal'">
        <personal :dataPersonal="personal" :tahunMasuk="schoolYearActive" />
      </div>
      <div v-if="current[0] === 'finance'">
        <finance :dataFinance="finance" :tahunMasuk="schoolYearActive" />
      </div>
      <div v-if="current[0] === 'location'">
        <location :dataLocation="location" :tahunMasuk="schoolYearActive" />
      </div>
      <div v-if="current[0] === 'achievement'">
        <achievement :dataAchievement="achievement" :tahunMasuk="schoolYearActive" />
      </div>
    </template>
  </div>
</template>

<script>
const LoadingState = () => import('@/components/app/LoadingState')
const Personal = () => import('@/views/Admin/StudentCandidate/Analytics/Personal')
const Finance = () => import('@/views/Admin/StudentCandidate/Analytics/Finance')
const Location = () => import('@/views/Admin/StudentCandidate/Analytics/Location')
const Achievement = () => import('@/views/Admin/StudentCandidate/Analytics/Achievement')
export default {
  components: {
    LoadingState,
    Personal,
    Finance,
    Location,
    Achievement,
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      current: ['personal'],
      years: [],
      schoolYearActive: '2021-2022',
      personal: {
        count_all_murid: 0,
        count_all_murid_laki_laki: 0,
        count_all_murid_perempuan: 0,
        count_all_murid_berkebutuhan_khusus: 0,
        data_chart_agama: [],
        data_chart_kewarganegaraan: [],
        count_all_murid_pending_ppdb: 0,
      },
      finance: {
        count_all_murid_orangtua_penghasilan_lebih_dari_20jt: 0,
        count_all_murid_kps_pkh: 0,
        count_all_murid_kip: 0,
        count_all_murid_pip: 0,
        data_map_murid_penghasilan: [],
        data_chart_penghasilan: [],
        data_chart_alasan_layak_pip: [],
      },
      location: {
        data_map_murid: [],
        data_chart_jarak_tempat_tinggal_ke_sekolah: [],
        data_chart_waktu_tempuh_ke_sekolah: [],
      },
      achievement: {
        count_all_murid_with_prestasi: 0,
        count_all_murid_without_prestasi: 0,
        count_all_murid_with_beasiswa: 0,
        count_all_murid_without_beasiswa: 0,
        data_chart_jenis_prestasi: [],
        data_list_tingkat_prestasi: [],
        data_chart_jenis_beasiswa: [],
        murid_paling_berprestasi: null,
      },
    }
  },
  methods: {
    handleClickTab({ item, key, keyPath }) {
      // console.log(key)
      this.fetchDataTab(key)
    },
    async fetchDataTab(key) {
      this.isLoading = true
      try {
        const storeLink = `ppdb/FETCH_${key.toUpperCase()}_ANALYTICS`
        const data = await this.$store.dispatch(storeLink, { selectedYear: this.schoolYearActive })
        this.isLoading = false
        // console.log('data', data)
        this[key] = data

        return new Promise((resolve) => resolve())
      } catch (error) {
        this.isLoading = false
        this.isError = true
        console.log('error', error)
        return new Promise((resolve, reject) => reject(error))
      }
    },
    async fetchBatch() {
      try {
        const { data, yearsActive } = await this.$store.dispatch('admin/FETCH_TAHUN_MASUK')
        this.years = data
        const dataActive = this.years.find(year => year.tahun === yearsActive.tahun)
        if (dataActive) {
          this.schoolYearActive = dataActive.tahun
        } else {
          this.schoolYearActive = this.years[0].tahun
        }
        return new Promise((resolve) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    handleMenuClick(e) {
      this.schoolYearActive = e.key
      this.fetchDataTab(this.current[0])
    },
  },
  async mounted() {
    try {
      await this.fetchBatch()
      await this.fetchDataTab('personal')
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style>
</style>
